<template>
  <v-app>
    <v-container
      fluid
    >
      <!--  -->
      <!-- <base-summary /> -->
      <v-row>
        <v-col
          cols="12"
          md="4"
          lg="4"
        >
          <v-text-field
            v-model="q"
            placeholder="Cari Judul Thread"
            append-icon="mdi-magnify"
            :label="$t('search')"
            color="secondary"
            hide-details
            @keyup="search"
          />
        </v-col>
        <!--  -->
        <v-col
          cols="12"
          md="2"
          lg="2"
          offset-md="6"
          offset-lg="6"
        >
          <v-btn
            to="/polling/create"
            left
            width="100%"
            color="primary"
          >
            <span class="mr-1">
              <v-icon light>mdi-pencil-plus</v-icon>
            </span>
            Buat Baru
          </v-btn>
        </v-col>
      </v-row>
      <!--  -->
      <v-row>
        <v-col
          cols="12"
          md="5"
          lg="12"
        >
          <!--  -->
          <base-material-card
            title="Polling"
            icon="mdi-lan-pending"
            color="primary"
            class="px-6 py-3"
          >
            <!--  -->
            <template>
              <!--  -->
              <div>
                <!--  -->
                <v-simple-table>
                  <!--  -->
                  <template v-slot:default>
                    <!--  -->
                    <thead>
                      <tr>
                        <th class="text-left primary--text">
                          #
                        </th>
                        <th class="text-left primary--text">
                          Pertanyaan
                        </th>
                        <th class="text-left primary--text">
                          Pembuat
                        </th>
                        <th class="text-left primary--text">
                          Dibuat pada
                        </th>
                        <th class="text-left primary--text">
                          Votes
                        </th>
                        <th class="text-center primary--text">
                          Aksi
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in polling.data"
                        :key="data.id"
                      >
                        <td>{{ index + polling.meta.from }}</td>
                        <td>{{ data.question }}</td>
                        <td>{{ data.user.name }}</td>
                        <td>{{ data.created_at | moment('D MMM YYYY') }}</td>
                        <td>{{ data.votes }}</td>
                        <td class="text-center">
                          <v-tooltip
                            bottom
                            color="blue"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <!--  -->
                              <v-btn
                                icon
                                color="blue"
                                v-bind="attrs"
                                :to="'/polling/' + data.id + '/show'"
                                v-on="on"
                              >
                                <v-icon small>
                                  mdi-eye
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Show</span>
                          </v-tooltip>

                          <v-tooltip
                            bottom
                            color="blue"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <!--  -->
                              <v-btn
                                icon
                                color="blue"
                                v-bind="attrs"
                                :to="'/polling/' + data.id + '/edit'"
                                v-on="on"
                              >
                                <v-icon small>
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>

                          <v-tooltip
                            bottom
                            color="red"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <!--  -->
                              <v-btn
                                color="red lighten-2"
                                icon
                                v-bind="attrs"
                                :to="'/polling/' + data.id + '/vote'"
                                v-on="on"
                              >
                                <v-icon small>
                                  mdi-timetable
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Vote</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div class="text-center">
                  <v-pagination
                    v-model="page"
                    :length="polling.meta.last_page"
                    total-visible="5"
                    @input="getResults"
                  />
                </div>
              </div>
            </template>
          </base-material-card>
        </v-col>
        <v-col cols="12">
          <template>
            <v-dialog
              v-model="dialog.open"
              width="500"
              :retain-focus="false"
            >
              <v-card>
                <v-card-title primary-title>
                  Confirmation
                </v-card-title>

                <v-card-text>
                  Anda yakin akan menghapus data Forum dengan judul "{{
                    dialog.title
                  }}"?
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-btn
                    color="primary"
                    @click="dialog.open = false"
                  >
                    BATAL
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    color="error"
                    @click="deleteItem(dialog.id)"
                  >
                    HAPUS
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'Polling',
    data () {
      return {
        polling: {
          meta: {},
          data: {},
        },
        dialog: {
          open: false,
          id: 0,
          title: '',
        },
        isLoading: false,
        page: parseInt(this.$route.query.page) || 1,
        last_page: parseInt(this.$route.query.last_page) || 1,
        q: this.$route.query.q || '',
        offset: this.$route.query.offset || 10,
        status: this.$route.query.status || '',
        sort: '-id',
      }
    },
    watch: {
      q (newVal) {
        this.$router.push({ query: { ...this.$route.query, q: newVal } })
      },
      '$route.query.q': function (val) {
        this.q = val
      },
      page (newVal) {
        this.$router.push({ query: { ...this.$route.query, page: newVal } })
      },
      '$route.query.page': function (val) {
        this.page = parseInt(val)
      },
      last_page (newVal) {
        this.$router.push({ query: { ...this.$route.query, last_page: newVal } })
      },
      '$route.query.last_page': function (val) {
        this.last_page = parseInt(val)
      },
      status (newVal) {
        this.$router.push({ query: { ...this.$route.query, status: newVal } })
        console.log('masuk')
      },
      '$route.query.status': function (val) {
        this.status = val
      },
    },
    mounted () {
      this.getResults()
    },
    methods: {
      openDialog (id, title) {
        this.dialog.open = true
        this.dialog.id = id
        this.dialog.title = title
      },
      search () {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          this.getResults()
        }, 700)
      },
      setPolling (data) {
        this.polling = data
      },
      getResults (page = this.page) {
        this.isLoading = false
        let search = ''
        if (this.q !== '') {
          search = '?q=' + this.q
        }
        const params = {
          entities: 'user,community,options',
          status: this.status,
          page: this.page,
          free: 1,
          sort: '-id',
          last_page: this.last_page,
        }
        axios.get('/v1/polling' + search, { params: params }).then(response => {
          this.setPolling(response.data)
        })
      },
    },
  }
</script>

<style></style>
