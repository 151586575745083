var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('v-text-field',{attrs:{"placeholder":"Cari Judul Thread","append-icon":"mdi-magnify","label":_vm.$t('search'),"color":"secondary","hide-details":""},on:{"keyup":_vm.search},model:{value:(_vm.q),callback:function ($$v) {_vm.q=$$v},expression:"q"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2","offset-md":"6","offset-lg":"6"}},[_c('v-btn',{attrs:{"to":"/polling/create","left":"","width":"100%","color":"primary"}},[_c('span',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("mdi-pencil-plus")])],1),_vm._v(" Buat Baru ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5","lg":"12"}},[_c('base-material-card',{staticClass:"px-6 py-3",attrs:{"title":"Polling","icon":"mdi-lan-pending","color":"primary"}},[[_c('div',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left primary--text"},[_vm._v(" # ")]),_c('th',{staticClass:"text-left primary--text"},[_vm._v(" Pertanyaan ")]),_c('th',{staticClass:"text-left primary--text"},[_vm._v(" Pembuat ")]),_c('th',{staticClass:"text-left primary--text"},[_vm._v(" Dibuat pada ")]),_c('th',{staticClass:"text-left primary--text"},[_vm._v(" Votes ")]),_c('th',{staticClass:"text-center primary--text"},[_vm._v(" Aksi ")])])]),_c('tbody',_vm._l((_vm.polling.data),function(data,index){return _c('tr',{key:data.id},[_c('td',[_vm._v(_vm._s(index + _vm.polling.meta.from))]),_c('td',[_vm._v(_vm._s(data.question))]),_c('td',[_vm._v(_vm._s(data.user.name))]),_c('td',[_vm._v(_vm._s(_vm._f("moment")(data.created_at,'D MMM YYYY')))]),_c('td',[_vm._v(_vm._s(data.votes))]),_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"blue","to":'/polling/' + data.id + '/show'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Show")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"blue","to":'/polling/' + data.id + '/edit'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red lighten-2","icon":"","to":'/polling/' + data.id + '/vote'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-timetable ")])],1)]}}],null,true)},[_c('span',[_vm._v("Vote")])])],1)])}),0)]},proxy:true}])}),_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.polling.meta.last_page,"total-visible":"5"},on:{"input":_vm.getResults},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)]],2)],1),_c('v-col',{attrs:{"cols":"12"}},[[_c('v-dialog',{attrs:{"width":"500","retain-focus":false},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Confirmation ")]),_c('v-card-text',[_vm._v(" Anda yakin akan menghapus data Forum dengan judul \""+_vm._s(_vm.dialog.title)+"\"? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog.open = false}}},[_vm._v(" BATAL ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem(_vm.dialog.id)}}},[_vm._v(" HAPUS ")])],1)],1)],1)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }